import * as Phaser from 'phaser';
import NinePatch from 'phaser3-rex-plugins/plugins/ninepatch.js';

export default class Button extends Phaser.GameObjects.Container {
  scene: Phaser.Scene;
  constructor(
    scene: Phaser.Scene,
    width: number,
    height: number,
    text: string,
    fnc: any,
    x?: number,
    y?: number
  ) {
    super(scene);
    this.scene = scene;

    var buttonNinePatch = new NinePatch(this.scene, {
      x: 0,
      y: 0,
      width: width,
      height: height,
      key: 'button_blue',
      columns: [25, undefined, 25],
      rows: [65, undefined, 79],
      preserveRatio: false,
    });

    const button = this.scene.add
      .existing(buttonNinePatch)
      .setTint(0x057acb)
      .setInteractive();

    const button_text = this.scene.add
      .text(0, 0, text, {
        fontFamily: 'Oswald',
        color: 'white',
        fontSize: '41px',
      })
      .setShadow(2, 2, '#000000', 1, true, true);
    button_text.setX(-button_text.width / 2);
    button_text.setY(-30);

    button.on('pointerup', () => {
      fnc();
    });

    button.on('pointerover', () => {
      button.setTint(0x50d500);
    });
    button.on('pointerout', () => {
      button.setTint(0x057acb);
    });

    this.add([button, button_text]);
    this.setX(x ? x : 0);
    this.setY(y ? y : 0);
  }
}

import * as Phaser from 'phaser';
import Server from '../services/Server';
//import { getCookie, setCookie, getCookies } from 'typescript-cookie';
import WebFont from 'webfontloader';
import { Storage } from '@capacitor/storage';
import { PhaserSingletonService } from '../phaser-singleton.module';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { isPlatform } from '@ionic/angular';
import { http } from '../helpers/HTTP';

export default class GameManager extends Phaser.Scene {
  constructor(public phaserInstance: PhaserSingletonService) {
    super('gamemanager');
  }

  async init() {
    if (!isPlatform('capacitor')) {
      GoogleAuth.initialize({
        scopes: ['profile', 'email'],
        clientId:
          '676168979053-269asgu8hpdv4hogkeo7oml1e9f5vskj.apps.googleusercontent.com',
        grantOfflineAccess: true,
      });
    }
  }

  async getUserData() {
    try {
      let googleUser = await GoogleAuth.refresh();
      console.log('refresh:', googleUser.accessToken);

      const data = await http(
        'https://www.all-soccer.ru/getuser.php?token=' + googleUser.accessToken
      );
      if (data.error) {
        PhaserSingletonService.user = undefined;
      } else if (data.sub !== undefined) {
        PhaserSingletonService.user = {
          id: data.sub,
          name: data.name,
          avatar: data.picture,
        };
      } else {
        PhaserSingletonService.user = undefined;
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  async main() {
    let data = await fetch(
      `../assets/lang/${PhaserSingletonService.language}.json`
    );
    PhaserSingletonService.languageConstants = await data.json();

    var element = document.createElement('style');
    document.head.appendChild(element);
    var sheet = element.sheet;
    var styles =
      '@font-face { font-family: "Oswald"; src: url("assets/fonts/oswald-v41-latin_cyrillic-regular.otf") format("opentype"); }\n';
    sheet.insertRule(styles, 0);

    WebFont.load({
      custom: {
        families: ['Oswald'],
      },
      active: () => {
        if (PhaserSingletonService.user === undefined) {
          this.scene.launch('googlesignup');
        } else {
          // this.scene.launch('game', {
          //   server: this.server,
          // });
          console.log(
            'PhaserSingletonService.user',
            PhaserSingletonService.user
          );
          this.scene.launch('lobby');
          //this.scene.launch('HUD');
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  async create() {
    setTimeout(async () => {
      await this.getUserData();
      this.main();
    }, 200);
  }
}

import * as Phaser from 'phaser';
import Player from '../schema/Player';
import NinePatch from 'phaser3-rex-plugins/plugins/ninepatch.js';
import Settings from './Settings';
import UserData from '../UserData';
import Seat from '../schema/Seat';
import { PhaserSingletonService } from 'src/phaser-singleton.module';

export type pHUD = {
  active: NinePatch;
  avatar: Phaser.GameObjects.Image;
  character_lv_bg_yellow: Phaser.GameObjects.Image;
  avatarFrame: Phaser.GameObjects.GameObject;
  name: Phaser.GameObjects.GameObject;
  points: Phaser.GameObjects.Text;
  bg: NinePatch;
};

export default class HUD extends Phaser.Scene {
  avatars?: Phaser.GameObjects.Image[] = [];
  playerHUD: pHUD[] = [];
  playersHUDs: Phaser.GameObjects.Container[] = [];
  hexagon_points: Phaser.GameObjects.Container[] = [];
  settings = new Settings();
  private user = new UserData();

  constructor(public phaserInstance: PhaserSingletonService) {
    super('HUD');
  }

  preload() {
    this.load.image('btn_icon_setting', 'assets/UI/btn_icon_setting.png');
    this.load.image('profile_img_frame', 'assets/UI/profile_img_frame.png');
    this.load.image('btn_icon_user', 'assets/UI/btn_icon_user.png');
    this.load.image('lobby_chest_bg', 'assets/UI/lobby_chest_bg.png');
    this.load.image(
      'character_lv_bg_yellow',
      'assets/UI/character_lv_bg_yellow.png'
    );
    this.load.image(
      'reward_month_frame_focus',
      'assets/UI/reward_month_frame_focus.png'
    );
  }

  create() {
    this.optionsButton();
    // метод вызывается после завершения загрузки файла
    this.load.on(
      'filecomplete',
      (progress, cacheKey, success, totalLoaded, totalFiles) => {
        const loaded = progress.split('_');
        switch (loaded[0]) {
          case 'avatar': // если загружен аватар
            // меняем аватарку
            this.playerHUD[loaded[1]].avatar.setTexture(progress).setScale(0.7);
            // запоминаем загруженную картинку, чтобы больше не загружать
            this.avatars[loaded[1] * 1] = progress;
            break;
        }
      },
      this
    );
  }

  // кнопка настроек
  optionsButton() {
    const { width, height } = this.sys.game.canvas;
    const btn_icon_setting = this.add
      .image(width - 80, 80, 'btn_icon_setting')
      .setInteractive();
    btn_icon_setting.on('pointerup', () => {
      this.scene.launch('options');
      btn_icon_setting.setScale(1.1);
    });
    btn_icon_setting.on('pointerout', () => {
      btn_icon_setting.setScale(1);
    });
    btn_icon_setting.on('pointerover', () => {
      btn_icon_setting.setScale(1.1);
    });
  }

  // список игроков
  playerList(seats: Seat[], activePlayer: number = -1, me: number = -1) {
    //console.log('REDRAW PLAYERS', me);
    let players: Player[] = [];
    seats.forEach((s) => {
      players.push(s.player);
    });
    const pHeight = 150;
    let counter = 0;
    let avatars = this.avatars;
    let isLoad = false;
    console.log('players', players);
    players.forEach((p) => {
      if (!this.playerHUD[counter]) {
        const hexagon = this.add
          .image(0, 0, 'hexagon')
          .setTint(this.settings.playerColors[counter]);
        const hexagon_points = this.add.text(-9, -23, '', {
          fontFamily: 'Oswald',
          color: 'white',
          fontSize: '30px',
          stroke: '#000000',
          strokeThickness: 3,
        });
        const hexagon_points_container = this.add
          .container(400, 100 + counter * pHeight, [hexagon, hexagon_points])
          .setVisible(false);
        this.hexagon_points.push(hexagon_points_container);

        // если контейнер для игрока на этом месте еще не создавался

        // подсветка текущего игрока
        var activePatch = new NinePatch(this, {
          x: 140,
          y: -3,
          width: 485,
          height: 135,
          key: 'reward_month_frame_focus',
          columns: [50, undefined, 50],
          rows: [50, undefined, 50],
          preserveRatio: true,
        });
        const active = this.add
          .existing(activePatch)
          .setVisible(counter === me);

        // фон
        var bgPatch = new NinePatch(this, {
          x: 140,
          y: 0,
          width: 450,
          height: 110,
          key: 'lobby_chest_bg',
          columns: [25, undefined, 25],
          rows: [25, undefined, 35],
          preserveRatio: false,
        });
        const bg = this.add.existing(bgPatch);

        // аватарка
        let avatar: any;
        if (!avatars[counter]) {
          // загружаем, только если не загружена
          console.log('AVATAR', p.avatar);
          this.load.image('avatar_' + counter, p.avatar);
          avatar = this.add.image(-30, -5, 'btn_icon_user').setScale(0.4);
          isLoad = true;
        } else {
          // иначе добавляем из загруженных ранее
          avatar = this.add.image(-30, -5, 'avatar_' + counter).setScale(0.4);
        }

        // рамка цветная рамка аватарки, для различия игроков
        const shape = this.make.graphics({});
        shape.fillStyle(this.settings.playerColors[counter]);
        const avatarFrame = shape.fillRect(-70, -45, 80, 80);

        // текст с именем
        const name = this.add.text(30, -25, p.name, {
          fontFamily: 'Oswald',
          color: 'white',
          fontSize: '32px',
        });
        if (name.width > 240) {
          name.setScale(240 / name.width);
        }
        // желтая плашка счетчика очков
        const character_lv_bg_yellow = this.add
          .image(315, -2, 'character_lv_bg_yellow')
          .setScale(0.65);
        // количество очков
        const points = this.add.text(355, -28, p.points.toString(), {
          fontFamily: 'Oswald',
          color: 'white',
          fontSize: '32px',
          stroke: '#000000',
          strokeThickness: 4,
        });
        points.x = 315 - points.width / 2;

        // запоминаем в массиве
        this.playerHUD.push({
          active,
          avatar,
          avatarFrame,
          name,
          character_lv_bg_yellow,
          points,
          bg,
        });

        // создаем контейнер
        const container = this.add.container(100, 100 + pHeight * counter, [
          active,
          bg,
          avatarFrame,
          avatar,
          name,
          character_lv_bg_yellow,
          points,
        ]);
        container.setScale(counter === activePlayer ? 1.15 : 1);
        if (counter === activePlayer) {
          container.setX(114);
        }

        // добавляем в масси для дальнейших манипуляций
        this.playersHUDs.push(container);
      } else {
        // если контейнер для игрока на этом месте уже создан

        // меняем количчество очков
        const points = this.playerHUD[counter].points;
        points.setText(p.points.toString());
        points.x = 315 - points.width / 2;

        //console.log(p.email, this.user.email);
        // подсвечиваем если это текущий игрокв
        this.playerHUD[counter].active.setVisible(
          p.id === PhaserSingletonService.user.id
        );

        // если сейчас ход этого игрока
        if (counter == activePlayer) {
          // увеличиваем его контейнер
          this.add.tween({
            targets: this.playersHUDs[counter],
            scale: 1.15,
            duration: 800,
          });
          this.playersHUDs[counter].setX(114);
        } else {
          // возвращаем мастшаб контейнера
          this.add.tween({
            targets: this.playersHUDs[counter],
            scale: 1,
            duration: 800,
          });
          this.playersHUDs[counter].setX(100);
        }
      }
      if (seats[counter].isCardPlaced) {
        this.playerHUD[counter].character_lv_bg_yellow.setTint(0x00ff00);
      } else {
        this.playerHUD[counter].character_lv_bg_yellow.clearTint();
      }

      counter++;
    });

    // сортируем игроков по количеству очков
    let pc = 0;
    let srt: any[] = [];
    players.forEach((p) => {
      srt.push({ id: pc, points: p.points });
      pc++;
    });
    srt.sort(function (a, b) {
      return parseFloat(b.points) - parseFloat(a.points);
    });
    // перемещаем контейнеры в порядке сортировки
    counter = 0;
    srt.forEach((s) => {
      this.add.tween({
        targets: this.playersHUDs[s.id],
        y: 100 + pHeight * counter,
        duration: 1500,
        ease: 'Power2',
      });

      counter++;
    });
    // начинаем загрузку изображений, если надо подгрузить новые аватарки
    if (isLoad) this.load.start();
  }

  spinner() {
    const { width, height } = this.scale;
    const x = width / 2;
    //const y = height / 2;
    const y = 120;

    const left = this.add.rectangle(-50, 0, 40, 75, 0x93dbe9, 1);
    const middle = this.add.rectangle(0, 0, 40, 75, 0x689cc5, 1);
    const right = this.add.rectangle(50, 0, 40, 75, 0x5e6fa3, 1);

    const container = this.add.container(x, y, [left, middle, right]);

    this.add.tween({
      targets: left,
      scaleY: 2,
      duration: 120,
      repeat: -1,
      repeatDelay: 300,
      yoyo: true,
      ease: Phaser.Math.Easing.Bounce.InOut,
    });

    this.add.tween({
      targets: middle,
      scaleY: 2,
      duration: 120,
      delay: 100,
      repeat: -1,
      repeatDelay: 300,
      yoyo: true,
      ease: Phaser.Math.Easing.Bounce.InOut,
    });

    this.add.tween({
      targets: right,
      scaleY: 2,
      duration: 120,
      delay: 200,
      repeat: -1,
      repeatDelay: 300,
      yoyo: true,
      ease: Phaser.Math.Easing.Bounce.InOut,
    });

    return container;
  }
}

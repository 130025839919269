import { Scene } from 'phaser';
import * as Phaser from 'phaser';
import NinePatch from 'phaser3-rex-plugins/plugins/ninepatch.js';
import { GameStatus } from '../schema/IFantaziumState';
import Game from '../scenes/Game';

export default class Card extends Phaser.GameObjects.Container {
  game?: Game;
  public id: number;
  private _inHand: boolean;

  constructor(id: number, _scene: Game, inHand = true) {
    if (!_scene || _scene.knownCards[id] !== undefined) {
      return;
    }

    var cardNinePatch = new NinePatch(_scene, {
      x: 0,
      y: 10,
      width: 300,
      height: 440,
      key: 'pass_reward_frame_blue',
      columns: [55, undefined, 55],
      rows: [65, undefined, 65],
      preserveRatio: false,
    });

    const cardBack = _scene.add
      .existing(cardNinePatch)
      .setName('cardBack')
      .setTint(0x057acb);
    let cardImage: Phaser.GameObjects.Image = undefined;

    fetch('https://www.all-soccer.ru/getcard.php?id=' + id)
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        data.forEach((f) => {
          _scene.load.image(
            'cardTexture_' + f.id,
            'https://chat.na4u.ru/fantazium/img.php?img=' + f.file
          );
        });
        _scene.load.start();
      });

    cardImage = _scene.add
      .image(0, -10, 'glow')
      .setScale(0.2)
      .setAlpha(0.2)
      .setName('cardImage_' + id);

    super(_scene);

    this.game = _scene;
    this.id = id;
    this._inHand = inHand;

    //, 500, 500, [cardBack, cardImage]

    var selectImage = new NinePatch(_scene, {
      x: 0,
      y: -5,
      width: 340,
      height: 470,
      key: 'reward_month_frame_focus',
      columns: [50, undefined, 50],
      rows: [50, undefined, 50],
      preserveRatio: true,
    }).setVisible(false);

    //const selectImage = _scene.add.image(0, 0, 'reward_month_frame_focus');

    this.add(selectImage);
    this.add(cardBack);
    this.add(cardImage);
    this.setName('card_' + id)
      .setName('card_' + id)
      .setInteractive(
        new Phaser.Geom.Rectangle(-150, -200, 300, 400),
        Phaser.Geom.Rectangle.Contains
      )
      .setVisible(true)
      .setData('type', inHand ? 'card' : 'vote_card')
      .setData('id', id)
      .setData('inHand', inHand);

    this.scene.add.existing(this);

    if (inHand) {
      _scene.input.setDraggable(this);
      _scene.myHand.push(this);
    } else {
      _scene.voteCards.push(this);
    }
    _scene.knownCards[id] = this;
  }

  // возврат карты на место в руке
  public returnCard() {
    if (
      this === this.game?.cardOnField &&
      !this.game?.cardOnField.inHand &&
      this.game?.server?.room?.state.cardsToVote.length === 0
    ) {
      //console.log('TF***', this.game?.server?.room?.state.gameStatus, this.game?.server?.room?.state.cardsToVote.length);

      this.toField();
      return;
    }
    this.game?.add.tween({
      targets: this,
      scale: 1,
      rotation:
        this.cardPosition > -1 && this.inHand
          ? this.game?.rotation[this.cardPosition]
          : 0,
      y:
        this.voteCardPosition > -1
          ? 430
          : 930 + this.game?.yOfset[this.cardPosition],
      x:
        this.voteCardPosition > -1
          ? 800 + 200 * this.voteCardPosition
          : 800 + 200 * this.cardPosition,
      duration: 200,
      ease: 'Power2',
      depth:
        this.voteCardPosition > -1 ? this.voteCardPosition : this.cardPosition,
    });
    if (
      !this.game?.cardOnField &&
      this.game?.server?.room?.state.gameStatus !== GameStatus.Vote
    ) {
      this.game?.confirmButton.setVisible(false);
    }
  }

  public toField() {
    //    console.log('toField()');
    this.game.add.tween({
      targets: this,
      scale: 1,
      y: this.game.fieldPosY,
      x: this.game.fieldPosX,
      duration: 200,
      ease: 'Power2',
      depth: 1,
    });
  }

  public preview() {
    if (this === this.game?.cardOnField) return;
    this.game.add.tween({
      targets: this,
      scale: 1.5,
      rotation: 0,
      y:
        this.voteCardPosition > -1 || this === this.game?.cardOnField
          ? 400
          : 750,
      duration: 200,
      ease: 'Power2',
      depth: 10,
    });
  }

  // увеличение размера карты
  public zoomCard() {
    this.game?.add.tween({
      targets: this,
      scale: 2.1,
      y: 500,
      duration: 200,
      ease: 'Power2',
      depth: 10,
    });
  }

  get cardPosition(): number {
    let order = 0;
    let sort = -1;
    this.game?.myHand.forEach((c) => {
      if (c?.id === this.id) {
        sort = order;
      }
      order++;
    });
    return sort;
  }

  set inHand(value: boolean) {
    this._inHand = value;
  }

  get inHand(): boolean {
    return this._inHand;
  }

  public setCardActive(value: boolean = true): void {
    (this.list[0] as Phaser.GameObjects.Image).setVisible(value);
  }
  public setBackGroundColor(color: number) {
    const bg = this.list[1] as Phaser.GameObjects.Image;
    if (color === undefined) {
      //bg.clearTint();
      bg.setTint(0x057acb);
    } else {
      bg.setTint(color);
    }
  }

  public get voteCardPosition(): number {
    let order = 0;
    let sort = -1;
    this.game?.voteCards.forEach((c) => {
      if (c?.id === this.id) {
        sort = order;
      }
      order++;
    });
    return sort;
  }
}

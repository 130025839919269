import * as Phaser from 'phaser';
import Popup from '../helpers/Popup';
import { PhaserSingletonService } from '../phaser-singleton.module';
import Button from '../helpers/Button';
import { Storage } from '@capacitor/storage';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import Server from '../services/Server';

export default class Lobby extends Phaser.Scene {
  language: string;
  popup: Popup;

  constructor(public phaserInstance: PhaserSingletonService) {
    super('lobby');
  }

  async preload() {
    this.load.image('button_blue', 'assets/UI/button_gray.png');
  }

  create() {
    console.log(
      'lobby PhaserSingletonService.user',
      PhaserSingletonService.user
    );
    this.popup = new Popup(this, 500, 600, '', false);

    const play = () => {
      if (PhaserSingletonService.server === undefined) {
        PhaserSingletonService.server = new Server();
      }
      this.scene.start('game', {
        server: PhaserSingletonService.server,
      });
      this.scene.start('HUD');
    };
    const tutorial = () => {
      console.log('tutorial');
    };
    const rules = () => {
      console.log('rules');
    };
    const options = () => {
      console.log('options');
    };
    const quit = () => {
      PhaserSingletonService.user = undefined;
      GoogleAuth.signOut();
      this.scene.start('googlesignup');
    };

    const buttons = [
      { title: 'ИГРАТЬ', fnc: play },
      { title: 'ОБУЧЕНИЕ', fnc: tutorial },
      { title: 'ПРАВИЛА', fnc: rules },
      { title: 'НАСТРОЙКИ', fnc: options },
      { title: 'ВЫХОД', fnc: quit },
    ];

    let counter = 0;
    buttons.forEach((b) => {
      let button = new Button(
        this,
        400,
        70,
        b.title,
        b.fnc,
        this.popup.x,
        this.popup.y - 200 + counter * 100
      );
      this.add.existing(button);
      counter++;
    });
  }
}

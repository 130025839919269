import * as Phaser from 'phaser';
import Popup from '../helpers/Popup';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import type NinePatch from 'phaser3-rex-plugins/plugins/ninepatch.js';
import { isPlatform } from '@ionic/angular';
import { Storage } from '@capacitor/storage';
import { PhaserSingletonService } from '../phaser-singleton.module';
import Button from '../helpers/Button';

export default class GoogleSignUp extends Phaser.Scene {
  popup: Popup;
  constructor(public phaserInstance: PhaserSingletonService) {
    super('googlesignup');
  }

  preload() {
    this.load.image('bg', 'assets/UI/popup_bg.png');
    this.load.image('bg_login', 'assets/UI/popup_bg_login.png');
    this.load.image('glow', 'assets/UI/popup_bg_glow_white.png');
    this.load.image('close', 'assets/UI/popup_btn_close.png');
    this.load.image('button_blue', 'assets/UI/button_gray.png');
    this.load.image('popup_title_line', 'assets/UI/popup_title_line.png');
  }

  async googleSignIn() {
    console.log('googleSignIn');
    try {
      let googleUser = await GoogleAuth.signIn();
      console.log('googleUser : ', googleUser);

      PhaserSingletonService.user = {
        id: googleUser.id,
        name: googleUser.name,
        avatar: googleUser.imageUrl,
      };

      Storage.set({ key: 'googleUser', value: JSON.stringify(googleUser) });

      this.scene.launch('lobby');
      //this.scene.launch('HUD');
    } catch (error) {
      console.log(error);
      this.popup.setVisible(true);
    }
  }

  create() {
    if (!isPlatform('capacitor')) {
      GoogleAuth.initialize({
        scopes: ['profile', 'email'],
        clientId:
          '676168979053-269asgu8hpdv4hogkeo7oml1e9f5vskj.apps.googleusercontent.com',
        grantOfflineAccess: true,
      });
    }

    this.popup = new Popup(
      this,
      720,
      400,
      PhaserSingletonService.languageConstants.Auth,
      false
    );

    const text = this.add.text(
      0,
      -100,
      PhaserSingletonService.languageConstants.AUTH_TEXT,
      {
        fontFamily: 'Oswald',
        color: 'white',
        fontSize: '25px',
        align: 'center',
      }
    );
    text.setX(0 - text.width / 2);
    text.setY(-text.height / 2 - 25);
    this.popup.add(text);

    this.popup.resizePopup(text.height + 280);

    const np = this.popup.list[0] as NinePatch;

    let button = new Button(this, 400, 70, 'ВОЙТИ', () => {
      this.googleSignIn();
      this.popup.setVisible(false);
    });
    this.popup.add(button);
    button.setY(np.height / 2 - button.height - 80);
  }
}

import * as Phaser from 'phaser';
import IFantaziumState, { GameStatus } from '../schema/IFantaziumState';
import Card from '../model/Card';
import type Server from '../services/Server';
import type HUD from './HUD';
import NinePatch from 'phaser3-rex-plugins/plugins/ninepatch.js';
import { Message } from '../schema/messages';
import { Toast } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import Settings from './Settings';
//import { setInterval } from 'timers';

export default class Game extends Phaser.Scene {
  server: Server;
  shift = 0;
  rotation = [-0.25, -0.2, 0, 0.2, 0.25];
  yOfset = [50, 15, 0, 15, 50];
  //rotation = [0, 0, 0, 0, 0];
  //yOfset = [0, 0, 0, 0, 0];
  knownCards: Card[] = [];
  HUD?: HUD;
  myHand: Card[] = [];
  settings = new Settings();

  voteCards: Card[] = [];
  suggestedCard?: Card;
  suggestedCardID?: number;
  cardOnField: Card = undefined;
  infoText: Phaser.GameObjects.Text = undefined;
  lockFieldIcon: Phaser.GameObjects.Image = undefined;
  confirmButton: Phaser.GameObjects.Container = undefined;
  fieldBlocked = false;
  fieldPosX = 1200;
  fieldPosY = 370;
  lockField = false;
  wordForm?: any;
  timer?: Phaser.Time.TimerEvent;
  moveDone: boolean = false;
  voteDone: boolean = false;
  field: NinePatch = undefined;
  toast?: Toast;
  pointer_over_sound: Phaser.Sound.BaseSound;
  drop_field_sound: Phaser.Sound.BaseSound;
  timedEvent?: any;

  constructor() {
    super('game');
  }

  preload() {
    this.load.image(
      'pass_reward_frame_blue',
      'assets/UI/pass_reward_frame_blue_bw.png'
    );
    this.load.image('glow', 'assets/UI/popup_bg_glow_white.png');
    this.load.image(
      'character_select_add_bg',
      'assets/UI/character_select_add_bg.png'
    );
    this.load.image('ribbon_bg_yellow', 'assets/UI/ribbon_bg_yellow.png');
    this.load.image('equipment_lock_icon', 'assets/UI/equipment_lock_icon.png');
    this.load.image('button_green', 'assets/UI/button_green.png');
    this.load.image('text_field', 'assets/UI/text_field.png');
    this.load.image(
      'skill_passive_rotate_white',
      'assets/UI/skill_passive_rotate_white.png'
    );
    this.load.image('hexagon', 'assets/UI/hexagon.png');

    this.load.html('wodrdSelectForm', 'assets/wodrdSelectForm.html');

    this.load.audio('pointer_over', 'assets/sounds/pointer_over.mp3');
    this.load.audio('drop_field', 'assets/sounds/drop_field.mp3');
  }

  init() {
    this.HUD = this.scene.get('HUD') as HUD;
  }
  async create(data: { server: Server }) {
    this.pointer_over_sound = this.sound.add('pointer_over');
    this.drop_field_sound = this.sound.add('drop_field');

    var toastBgPatch = new NinePatch(this, {
      x: this.fieldPosX,
      y: this.fieldPosY,
      width: 350,
      height: 80,
      key: 'text_field',
      columns: [25, undefined, 25],
      rows: [60, undefined, 60],
      preserveRatio: true,
    });
    this.field = this.add.existing(toastBgPatch).setDepth(90).setAlpha(0.9);

    this.toast = new Toast(this, {
      x: 700,
      y: 500,
      background: toastBgPatch,
      text: this.add.text(0, 0, '', { fontSize: '34px' }),
      duration: {
        in: 100,
        hold: 1800,
        out: 100,
      },

      space: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 10,
      },
    });
    this.add.existing(this.toast).setDepth(30);

    this.wordForm = this.add
      .dom(2300, 400)
      .createFromCache('wodrdSelectForm')
      .setVisible(false);

    this.wordForm.addListener('click');
    this.wordForm.addListener('keyup');

    this.wordForm.on('keyup', (event) => {
      if (event.target.name == 'query') {
        var query = this.wordForm.getChildByName('query') as HTMLInputElement;

        fetch('https://www.all-soccer.ru/getword.php?q=' + query.value)
          .then(function (response) {
            return response.json();
          })
          .then((data) => {
            var selectedWord = this.wordForm.getChildByID(
              'selectedWord'
            ) as HTMLSelectElement;
            for (let i = selectedWord.options.length; i >= 0; i--) {
              selectedWord.remove(i);
            }
            data.forEach((e) => {
              selectedWord[selectedWord.options.length] = new Option(e, e);
            });
            selectedWord.selectedIndex = 0;
          })
          .catch(function () {});
      }
    });

    this.wordForm.on('click', (event) => {
      if (event.target.name === 'loginButton') {
        var selectedWord = this.wordForm.getChildByID(
          'selectedWord'
        ) as HTMLSelectElement;
        this.server?.room?.send(Message.WordAndCardSelection, {
          word: selectedWord.options[selectedWord.selectedIndex].value,
          card: this.cardOnField.getData('id'),
        });
        this.wordForm.setVisible(false);
        this.moveDone = true;
        this.cardOnField.disableInteractive();
      }
    });

    var graphics = this.add.graphics();
    graphics.lineStyle(2, 0xffff00);

    for (let i = 0; i < 5; i++) {
      let zone = this.add
        .zone(760 + 200 * i, 900, 200, 440)
        .setRectangleDropZone(200, 440)
        .setName('position_' + i);
      //graphics.strokeRect(zone.x - zone.input.hitArea.width / 2, zone.y - zone.input.hitArea.height / 2, zone.input.hitArea.width, zone.input.hitArea.height);
    }

    const zone = this.add
      .zone(this.fieldPosX, this.fieldPosY, 350, 500)
      .setRectangleDropZone(350, 500)
      .setName('field');

    var fieldPatch = new NinePatch(this, {
      x: this.fieldPosX,
      y: this.fieldPosY,
      width: 350,
      height: 500,
      key: 'character_select_add_bg',
      columns: [38, undefined, 38],
      rows: [45, undefined, 45],
      preserveRatio: true,
    });
    this.field = this.add.existing(fieldPatch);

    this.lockFieldIcon = this.add
      .image(this.fieldPosX, this.fieldPosY, 'equipment_lock_icon')
      .setAlpha(0.4)
      .setVisible(this.fieldBlocked);

    var ribbonPatch = new NinePatch(this, {
      x: this.fieldPosX,
      y: this.fieldPosY - 290,
      width: 780,
      height: 90,
      key: 'ribbon_bg_yellow',
      columns: [150, undefined, 150],
      rows: [152, undefined, undefined],
      preserveRatio: true,
    });
    const ribbon = this.add.existing(ribbonPatch);

    var button_greenPatch = new NinePatch(this, {
      x: 0,
      y: 0,
      width: 220,
      height: 90,
      key: 'button_green',
      columns: [25, undefined, 25],
      rows: [65, undefined, 79],
      preserveRatio: true,
    });
    let button_green = this.add.existing(button_greenPatch);

    let confirmText = this.add.text(0, 0, 'Подтвердить', {
      fontFamily: 'Oswald',
      color: 'white',
      fontSize: '32px',
      stroke: '#000000',
      strokeThickness: 4,
    });
    confirmText.setX(-confirmText.width / 2);
    confirmText.setY(-confirmText.height / 2 - 7);

    const geom = new Phaser.Geom.Rectangle(-110, -45, 220, 90);
    this.confirmButton = this.add
      .container(this.fieldPosX, this.fieldPosY - 210, [
        button_green,
        confirmText,
      ])
      .setVisible(false)
      .setDepth(30)
      .setInteractive(geom, Phaser.Geom.Rectangle.Contains);

    this.confirmButton.on('pointerup', () => {
      if (this.server?.room?.state.gameStatus === GameStatus.Vote) {
        this.voteDone = true;
        this.ribbonText();
        this.server?.room?.send(Message.CardVote, {
          card: this.suggestedCard.id,
        });
      } else {
        this.cardOnField?.disableInteractive().setCardActive(false);
        this.server?.room?.send(Message.cardPlaced, {
          card: this.cardOnField.id,
        });
        this.moveDone = true;
      }
      this.confirmButton.setVisible(false);
    });

    this.infoText = this.add.text(this.fieldPosX, this.fieldPosY - 322, '', {
      fontFamily: 'Oswald',
      color: 'white',
      fontSize: '32px',
      stroke: '#000000',
      strokeThickness: 4,
    });

    this.ribbonText();
    //this.infoText.setText('');
    //infoText.setShadow(2, 2, '#000000', 0, true, true);

    //    console.log(zone);
    // var graphics = this.add.graphics();
    // graphics.lineStyle(2, 0xffff00);
    // graphics.strokeRect(zone.x - zone.input.hitArea.width / 2, zone.y - zone.input.hitArea.height / 2, zone.input.hitArea.width, zone.input.hitArea.height);

    const { width, height } = this.sys.game.canvas;

    this.scale.displaySize.setAspectRatio(width / height);
    this.scale.refresh();

    const { server } = data;
    this.server = server;

    await this.server?.join();

    if (!this.server) {
      throw new Error('Server instance missing');
    }

    this.inputSetup();
    this.eventsSetup();

    this.load.on(
      'filecomplete',
      (progress, cacheKey, success, totalLoaded, totalFiles) => {
        const loaded = progress.split('_');
        //      console.log('loaded', loaded);
        switch (loaded[0]) {
          case 'cardTexture': // если загружен аватар
            let container = this.myHand.find(
              (c) => c.name === 'card_' + loaded[1]
            );
            if (!container) {
              container = this.voteCards.find(
                (c) => c.name === 'card_' + loaded[1]
              );
            }
            let img = container?.list[2] as Phaser.GameObjects.Image;
            img?.setTexture(progress).setAlpha(1).setScale(1);
            break;
        }
      }
    );

    console.log('create Game scene');
  }

  drawHand = () => {
    if (this.server.room.state.gameStatus === undefined) {
      return;
    }
    let p = 0;
    this.myHand.forEach((c) => {
      if (c === this.cardOnField) {
        c.toField();
      } else {
        c.setX(800 + 200 * p)
          .setRotation(this.rotation[p])
          .setY(930 + this.yOfset[p])
          .setVisible(true)
          .setScale(1)
          .setDepth(p)
          .setInteractive();
      }
      p++;
    });
  };

  drawVoteCards = (colorize: boolean = false) => {
    let p = 0;
    this.voteCards.forEach((c) => {
      if (!c.inHand) {
        c.returnCard();
      }
      c.setVisible(true).setScale(1).setDepth(p).setInteractive();
      if (c.cardPosition < 0) {
        this.add.tween({
          targets: c,
          x: 800 + 200 * p,
          y: 430,
          duration: 700,
          rotation: 0,
          ease: 'Power3',
        });
      } else {
        c.setX(800 + 200 * p).setY(430);
      }

      if (c.cardPosition > -1) {
        c.setBackGroundColor(this.settings.playerColors[this.mySeat()]);
      }
      if (this.suggestedCardID === c.id) {
        c.setCardActive(true);
      }
      if (colorize) {
        c.setBackGroundColor(this.settings.playerColors[p]);
      }
      p++;
    });
    this.cardOnField?.returnCard();
  };

  eventsSetup(): void {
    this.server?.onceStateChanged(() => {
      const state = this.server?.room?.state;
      //console.log('A STATE', state);
      if (
        state.gameStatus === GameStatus.PlayerSelectWordAndCard &&
        !this.isMyTurn()
      ) {
        this.fieldBlocked = true;
        this.lockFieldIcon.setVisible(true);
      }

      this.server?.room?.state.cardsToVote.forEach((c) => {
        if (this.cardOnField?.getData('id') * 1 === c) {
          this.voteCards.push(this.cardOnField);
          this.cardOnField = undefined;
        } else if (this.knownCards[c] !== undefined) {
          new Card(c, this, false);
        }
      });
      this.HUD.playerList(state?.seats, state.activePlayer, this.mySeat());
      this.drawHand();
      this.drawVoteCards();
    });

    this.server?.onGameStatusChanged((_state: IFantaziumState) => {
      this.ribbonText();
      const state = this.server?.room?.state;
      let hud = this.HUD;

      switch (state.gameStatus) {
        case GameStatus.CalculateTurnResults:
          //console.log(state.cardsToVote);
          this.HUD.playerList(state.seats, state.activePlayer, this.mySeat());
          this.voteCards.sort((a, b) => {
            return (
              state.cardsToVote.indexOf(a.id) - state.cardsToVote.indexOf(b.id)
            );
          });
          this.suggestedCard?.setCardActive(false);
          this.drawVoteCards();
          this.field.setVisible(false);
          //console.log('state.cardsToVote', state.cardsToVote);

          const idealCard = state.cardsToVote[state.activePlayer];
          let pointsIncrement = [...Array(state.MaxClients).fill(0)];
          let pos_points = [...Array(state.MaxClients).fill(0)];
          state.votes.forEach((card, index) => {
            if (index !== state.activePlayer) {
              pos_points[index] = state.cardsToVote.indexOf(card);
              if (card === idealCard) {
                pointsIncrement[state.activePlayer] +=
                  (pointsIncrement[state.activePlayer] === 0 ? 2 : 0) + 1;
                //pointsIncrement[index] += pointsIncrement[index] === 0 ? 2 : 0;
                pointsIncrement[index]++;
              } else if (card !== undefined) {
                let op = state.cardsToVote.indexOf(card);
                if (op > -1) {
                  pointsIncrement[op]++;
                }
              }
            }
          });
          if (
            pointsIncrement[state.activePlayer] ===
            2 + state.MaxClients - 1
          ) {
            pointsIncrement[state.activePlayer] = -2;
          }

          //console.log('results', idealCard, state.votes, pos_points, pointsIncrement, state.cardsToVote);

          let ocup = [...Array(state.MaxClients).fill(0)];
          state.cardsToVote.forEach((c, index) => {
            hud.hexagon_points[index].setVisible(true).setAlpha(1);
            (hud.hexagon_points[index].list[1] as Phaser.GameObjects.Text)
              .setText(pointsIncrement[index].toString())
              .setVisible(false);
            hud.add.tween({
              targets: hud.hexagon_points[index],
              x:
                index === state.activePlayer
                  ? 600
                  : 730 + 200 * pos_points[index],
              y:
                index === state.activePlayer
                  ? hud.playersHUDs[index].y
                  : 280 + 80 * ocup[pos_points[index]],
              duration: 1500,
              ease: 'Power2',
              completeDelay: 9000,
              onComplete: () => {
                var tweens = this.tweens.getTweensOf(hud.hexagon_points, true);
                //console.log('TWEENS', tweens);

                hud.add.tween({
                  targets: hud.hexagon_points[index],
                  y: hud.playersHUDs[index].y,
                  x: 580,
                  duration: 1200,
                  ease: 'Power2',
                  completeDelay: 3000,
                  onComplete: () => {
                    hud.add.tween({
                      targets: hud.hexagon_points[index],
                      alpha: 0,
                      duration: 200,
                    });
                    hud.playerHUD[index].points.setText(
                      (
                        state.seats[index].player.points +
                        pointsIncrement[index]
                      ).toString()
                    );
                    hud.playerHUD[index].points.setX(
                      hud.playerHUD[index].character_lv_bg_yellow.x -
                        hud.playerHUD[index].points.width / 2
                    );
                  },
                });
              },
            });
            if (index !== state.activePlayer) ocup[pos_points[index]]++;
          });

          //console.log('pointsIncrement[state.activePlayer]', pointsIncrement[state.activePlayer]);

          var counter = 1;
          const randomActiveCard = () => {
            let nc = this.getRandomIntInclusive(0, state.MaxClients - 1);
            this.voteCards.forEach((c, i) => {
              c.setCardActive(i === nc);
            });
            counter++;
            if (counter > 15) {
              this.timedEvent.remove(false);
              this.voteCards[nc].setCardActive(false);
              this.voteCards[state.activePlayer].setCardActive(true);
              this.add.tween({
                targets: this.voteCards[state.activePlayer],
                scale: 1.8,
                duration: 1000,
                completeDelay: 200,
                onComplete: () => {
                  this.add.tween({
                    targets: this.voteCards[state.activePlayer],
                    scale: 1,
                    duration: 500,
                    onComplete: () => {
                      this.suggestedCardID = undefined;
                      this.drawVoteCards(true);
                      hud.hexagon_points.forEach((h) => {
                        (h.list[1] as Phaser.GameObjects.Text).setVisible(true);
                      });
                    },
                  });
                },
              });
            }
          };

          this.timedEvent = this.time.addEvent({
            delay: 300,
            callback: randomActiveCard,
            callbackScope: this,
            loop: true,
          });

          break;
        case GameStatus.Vote:
          this.field.setVisible(false);
          break;
        case GameStatus.PlayerSelectWordAndCard:
          this.timedEvent?.remove(false);
          hud.hexagon_points?.forEach((h) => {
            h.setVisible(false);
          });
          if (this.isMyTurn()) {
          } else {
            this.fieldBlocked = true;
            this.lockFieldIcon.setVisible(this.fieldBlocked);
          }
          this.cardOnField = undefined;

          this.voteCards.forEach((c) => {
            this.myHand = this.myHand.filter((obj) => obj !== c);
          });
          this.knownCards.forEach((c, i) => {
            if (this.myHand.indexOf(c) === -1) {
              this.add.tween({
                targets: c,
                x: -300,
                y: -300,
                duration: 1500,
                ease: 'Power3',
                onComplete: () => {
                  c.destroy();
                  this.knownCards.slice(i, 1);
                },
              });
            }
          });
          this.voteCards = [];
          this.voteDone = false;
          this.moveDone = false;
          this.field.setVisible(true);
          this.fieldBlocked = !this.isMyTurn();
          this.suggestedCard = undefined;

          this.drawHand();
          this.drawVoteCards();
          break;
        case GameStatus.OtherPlayersSelectCards:
          if (this.isMyTurn()) {
          } else {
            this.fieldBlocked = false;
            this.lockFieldIcon.setVisible(this.fieldBlocked);
          }
          break;
        default:
          break;
      }
    });

    this.server?.onMyHandChanged((hand: number[]) => {
      hand.forEach((c) => {
        new Card(c, this);
      });
      this.drawHand();
    });

    this.server?.onMyVoteCard((card: number) => {
      this.suggestedCardID = card;
      this.voteDone = true;
    });

    this.server?.onMyFieldCard((card: number) => {
      if (!this.cardOnField) {
        let shift = undefined;
        this.myHand.forEach((c) => {
          if (c.getData('id') * 1 === card) {
            this.cardOnField = c;
            c.inHand = false;
            this.cardOnField.disableInteractive();
            shift = c.cardPosition;
          }
        });
        if (shift !== undefined) {
          this.myHand[shift] = this.myHand[4];
          this.myHand[4] = this.cardOnField;
          this.moveDone = true;
        }
        this.drawHand();
      }
    });

    // this.server?.onSeatsChanged((players: Player[]) => {
    //   this.HUD.playerList(players);
    // });
  }
  inputSetup(): void {
    this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
      gameObject.x = dragX;
      gameObject.y = dragY;
    });

    // чтобы разрешить нажатие правой кнопки мыли, блокируем вызов контенского меню
    this.input.mouse.disableContextMenu();

    // меняем местами карты, когда они переносятся на место друг друга
    this.input.on('dragenter', (pointer, gameObject, dropZone) => {
      if (dropZone.name !== 'field') {
        // если дропзона это не игровое поле
        const leavePosition = gameObject.cardPosition;
        let dz = dropZone.name.split('_')[1] * 1;
        if (this.cardOnField !== undefined && dz === this.myHand.length - 1) {
          dz--;
        }
        if (leavePosition !== dz) {
          this.myHand[leavePosition] = this.myHand[dz];
          this.myHand[dz] = gameObject;
          this.myHand[leavePosition]?.returnCard();
        }
      }
    });

    // при начале перетаскивания поднимаем карту на самый верх и масштабируем в единицу
    this.input.on('dragstart', (pointer, gameObject) => {
      gameObject.setScale(1).setDepth(10);
      this.confirmButton.setVisible(false);
    });

    // если бросили карту за пределами дродзон, возвращаем ее в руку
    this.input.on('dragend', (pointer, gameObject: Card, dropZone) => {
      if (!dropZone && !gameObject?.inHand && !this.moveDone) {
        gameObject.inHand = true;
        this.cardOnField = undefined;
        gameObject?.setCardActive(false);
        this.tweens.add({
          targets: this.wordForm,
          x: 2300,
          duration: 500,
          ease: 'Power3',
          onComplete: () => {
            this.wordForm?.setVisible(false);
          },
        });
        this.ribbonText();
        gameObject?.returnCard();
      }
      if (
        this.cardOnField &&
        this.server?.room?.state.gameStatus ===
          GameStatus.OtherPlayersSelectCards &&
        !this.isMyTurn() &&
        !this.moveDone
      ) {
        this.confirmButton.setVisible(true);
      }
    });

    this.input.on('drop', (pointer, gameObject: Card, dropZone) => {
      switch (dropZone.name) {
        case 'position_0':
        case 'position_1':
        case 'position_2':
        case 'position_3':
        case 'position_4':
          // если это карта с игрового поля, обнуляем
          if (!gameObject.inHand) {
            this.cardOnField?.setCardActive(false);
            this.cardOnField = undefined;
          }
          gameObject.inHand = true;
          break;
        case 'field':
          if (this.moveDone) {
            return;
          }
          if (this.fieldBlocked) {
            gameObject?.returnCard();
            return;
          }
          const cp = gameObject.cardPosition;
          this.myHand = this.myHand.filter((c) => c != gameObject);
          this.myHand.push(gameObject);
          for (let i = cp; i < 5; ++i) {
            this.myHand[i]?.returnCard();
          }
          if (this.cardOnField) {
            this.cardOnField.inHand = true;
            this.cardOnField?.setCardActive(false);
            this.cardOnField?.returnCard();
          }
          this.cardOnField = gameObject;
          this.cardOnField?.setCardActive(true);
          gameObject.inHand = false;
          gameObject.toField();
          this.drop_field_sound.play();

          if (
            this.server?.room?.state.gameStatus ===
              GameStatus.PlayerSelectWordAndCard &&
            this.isMyTurn()
          ) {
            this.wordForm.setVisible(true);
            this.tweens.add({
              targets: this.wordForm,
              x: 1620,
              duration: 500,
              ease: 'Power3',
            });
          }
          if (
            this.server?.room?.state.gameStatus ===
              GameStatus.OtherPlayersSelectCards &&
            !this.isMyTurn()
          ) {
            this.confirmButton.setVisible(true);
          }
          this.ribbonText();

          break;
        default:
          gameObject?.returnCard();
          break;
      }

      // убираем форму
      if (this.cardOnField === undefined) {
        this.tweens.add({
          targets: this.wordForm,
          x: 2300,
          duration: 500,
          ease: 'Power3',
          onComplete: () => {
            this.wordForm?.setVisible(false);
          },
        });
        this.ribbonText();
      }
    });
    // увеличить карту можно колесом мыши
    this.input.on(
      'wheel',
      function (pointer, gameObjects, deltaX, deltaY, deltaZ) {
        if (gameObjects[0] instanceof Card) gameObjects[0].zoomCard();
      }
    );

    // при отжатии кнопки мыши
    this.input.on('pointerup', (pointer: Phaser.Input.Pointer, gameObjects) => {
      if (!(gameObjects[0] instanceof Card)) {
        return;
      }
      if (pointer.button == 2) {
        // если это правак кнопка
        gameObjects[0].zoomCard(); // увеличиваем размер карты
      } else {
        if (this.server?.room?.state.gameStatus === GameStatus.Vote) {
          //console.log('pointerup', this.voteDone);
          if (this.voteDone) {
            return;
          }
          if (gameObjects[0]?.voteCardPosition > -1) {
            if (this.isMyTurn()) {
              this.toast
                .setX(pointer.worldX)
                .setY(pointer.worldY + 60)
                .showMessage('Вы не голосуете во время своего хода');
            } else if (gameObjects[0]?.cardPosition > -1) {
              this.toast
                .setX(pointer.worldX)
                .setY(pointer.worldY + 60)
                .showMessage('Нельзя голосовать за свою карту');
              //this.suggestedCard?.setBackGroundColor(undefined);
              this.suggestedCard?.setCardActive(false);
              this.suggestedCard = undefined;
            } else {
              //console.log(gameObjects[0]?.id);
              //this.suggestedCard?.setBackGroundColor(undefined);
              this.suggestedCard?.setCardActive(false);

              this.suggestedCard = gameObjects[0];

              this.suggestedCard?.setCardActive(true); //.setBackGroundColor(0x0000ff);
              this.confirmButton.setDepth(130).setVisible(true);
            }
          }
        }
        if (gameObjects[0] instanceof Card) gameObjects[0]?.returnCard(); // возвращаем карту на место
      }
    });

    // увеличиваем размер карты при наведении курсора
    this.input.on('pointerover', (pointer, gameObject) => {
      if (gameObject[0] instanceof Card) {
        gameObject[0].preview();
        if (!this.pointer_over_sound.isPlaying) this.pointer_over_sound.play();
      }
    });

    this.input.on('pointerout', function (pointer, gameObjects: Card) {
      if (!(gameObjects[0] instanceof Card)) return;
      gameObjects[0]?.returnCard();
    });
  }

  mySeat(): number {
    let me = 0;
    for (let s of this.server?.room?.state?.seats) {
      if (s.player.id === this.server?.room?.sessionId) {
        return me;
      }
      me++;
    }
    return -1;
  }

  isMyTurn(): boolean {
    const state = this.server?.room.state;
    return state.activePlayer === this.mySeat();
  }

  ribbonText(): void {
    let txt = '';
    //console.log('txt', this.server?.room?.state);
    switch (this.server?.room?.state.gameStatus) {
      case GameStatus.Pending:
        txt = 'Дождитесь подключение остальных игроков';
        break;
      case GameStatus.PlayerSelectWordAndCard:
        txt = this.isMyTurn()
          ? 'Выберите ' + (this.cardOnField !== undefined ? 'слово' : 'карту')
          : 'Дождитесь выбора слова';
        break;
      case GameStatus.OtherPlayersSelectCards:
        txt = this.isMyTurn()
          ? 'Дождитесь пока дргие игроки выберут карты'
          : this.moveDone
          ? 'Дождитесь других игроков'
          : 'Выберите карту на слово ' +
            this.server?.room.state.currentWord.toUpperCase();
        break;
      case GameStatus.Vote:
        txt = this.isMyTurn()
          ? 'Дождитесь пока дргие игроки выберут карты'
          : this.voteDone
          ? 'Дождитесь пока остальные игроки угадают карту'
          : 'Попробуйте угадать загаданную карту на слово ' +
            this.server?.room.state.currentWord.toUpperCase();
        break;
      case GameStatus.CalculateTurnResults:
        txt = 'Подсчет результатов хода';
        break;
      default:
        break;
    }
    this.infoText.setText(txt);
    let scale = 680 / this.infoText.width;
    if (scale > 1) scale = 1;
    this.infoText.setScale(scale);
    this.infoText.setX(this.fieldPosX - (this.infoText.width * scale) / 2);
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}

import { Storage } from '@capacitor/storage';

export default class UserData {
  id: number;
  name: string;
  email: string;
  avatar: string;
  constructor() {
    // Storage.get({ key: 'googleUser' }).then((user) => {
    //   const json = JSON.parse(user.value);
    //   console.log(json);
    //   this.id = json.id;
    //   this.name = json.name;
    //   this.email = json.email;
    //   this.avatar = json.imageUrl;
    //   // const urlParams = new URLSearchParams(window.location.search);
    //   // this.id = Number(urlParams.get('id'));
    //   // this.name = String(urlParams.get('name'));
    //   // this.email = String(urlParams.get('email'));
    //   console.log(this);
    // });
    // this.id = 3;
    // this.name = 'Denis';
    // this.email = 'cook@google.com';
  }
}

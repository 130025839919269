import { Client, Room } from 'colyseus.js';
import UserData from '../UserData';
import { Schema } from '@colyseus/schema';
import * as Phaser from 'phaser';
import IFantszuimState from '../schema/IFantaziumState';
import { Message } from '../schema/messages';
import type Player from '../schema/Player';
import { environment } from '../environments/environment';
import { PhaserSingletonService } from 'src/phaser-singleton.module';

// через сервер идет общение с Колизеем
export default class Server {
  public client: Client;
  // тестовые данные пользователя берем пока из адресной строки
  //private user = new UserData();
  private events: Phaser.Events.EventEmitter;
  public room?: Room<Schema & IFantszuimState>;

  constructor() {
    // создаем клиента, который будет общаться с сервером
    this.client = new Client(environment.SERVER_COLYSEUS);
    // эмиттер событий
    this.events = new Phaser.Events.EventEmitter();
    //console.log('USER', this.user);
  }

  async join() {
    // достаем из хранилища идентификаторы комнаты и сессии, сохраненные после успешного присоединения
    const sessionId = localStorage.getItem(
      'sessionId' + PhaserSingletonService.user.id.toString()
    );
    const roomId = localStorage.getItem(
      'roomId' + PhaserSingletonService.user.id.toString()
    );
    if (!roomId) {
      // если идентификатора нет
      await this.joinOrCreate(); // создаем новую комнату или присоединяемся к существующей
    } else {
      try {
        // иначе пытаемся заново присоединиться к той, к которой ранее успешно подключались
        console.log('reconnect', roomId, sessionId);
        this.room = await this.client?.reconnect(roomId, sessionId);
        console.log('RECONNECTED successfully');
      } catch (e) {
        // если не получилось
        console.warn('join error', e);
        // удаляем из хранилища сохраненные идентификаторы
        localStorage.removeItem(
          'sessionId' + PhaserSingletonService.user.id.toString()
        );
        localStorage.removeItem(
          'roomId' + PhaserSingletonService.user.id.toString()
        );
        // создаем новую комнату или присоединяемся к существующей
        await this.joinOrCreate();
      }

      //      console.log('==A ROOM', this.room);

      this.room.onMessage(Message.myHand, (message: IFantszuimState) => {
        this.events.emit('my-hand-changed', message);
      });

      this.room.onMessage(Message.myFieldCard, (message: IFantszuimState) => {
        this.events.emit('on-field-card', message);
      });

      this.room.onMessage(Message.myVoteCard, (message: IFantszuimState) => {
        this.events.emit('on-vote-card', message);
      });

      this.room.onMessage(Message.CardSelection, (message: IFantszuimState) => {
        this.events.emit('card-selection', message);
      });

      this.registerCallbacks(); // регистрируем колбэки
    }
  }

  async joinOrCreate() {
    // подключаемся к игровой комнате
    console.log('Joining');
    console.log(
      'PhaserSingletonService.user.id',
      PhaserSingletonService.user.id
    );
    try {
      // ждем когда клиент соединится с Ареной и подключится или создаст новую комнату с типом IFantszuimState
      // в качестве параметров передаем данные пользователя
      this.room = await this.client?.joinOrCreate<IFantszuimState & Schema>(
        'fantazium',
        {
          avatar: PhaserSingletonService.user.avatar,
          name: PhaserSingletonService.user.name,
          email: PhaserSingletonService.user.email,
          id: PhaserSingletonService.user.id,
        }
      );
      // записываем в хранилище идентификатор комнаты и сессии для повторного соединения
      localStorage.setItem(
        'sessionId' + PhaserSingletonService.user.id.toString(),
        this.room?.sessionId
      );
      localStorage.setItem(
        'roomId' + PhaserSingletonService.user.id.toString(),
        this.room?.id
      );

      this.registerCallbacks(); // регистрируем колбэки
    } catch (error) {
      console.error(error);
    }
  }

  // регистрируем колбэки
  // поскольку это нужно проделать и для присоединенияи для переприсоединения
  // выносим в функцию, вызываемую из двух этих мест
  registerCallbacks() {
    // событие сработает, когдо сервер изменить статус
    // для переприсоединения нужно зарегистрировать его отдельно
    this.room.onStateChange((state) => {
      this.events.emit('on-state-changed', state);
    });

    // событие сработат, когда что-то в статусе изменится
    this.room.state.onChange = this.onChangeSome;

    // за изменениями массивов нужно следить отдельно
    // при добавлении и удалении элементов сработают специальные колбэки
    // нужно привести к типу any, иначе TypeScript ругается, не видя колбэки в интерфейсе
    (this.room.state.seats as any).onAdd = (player, key) => {
      this.events.emit('seats-changed', this.room.state.seats);
    };

    (this.room.state.seats as any).onRemove = (player, key) => {
      this.events.emit('seats-changed', this.room.state.seats);
    };
  }

  // здесь отслеживаем изменения отдельных частей состояния игры
  onChangeSome = (changes) => {
    //console.log('!!some change!!');
    changes.forEach((ch) => {
      //console.log('some change', ch);
      switch (ch.field) {
        case 'seats':
          this.events.emit('game-status-changed', ch.value);
          break;
        case 'gameStatus':
          this.events.emit('game-status-changed', ch.value);
          break;
        case 'players':
          this.events.emit('seats-changed', ch.value);
          break;
      }
    });
  };

  WordAndCardSelection() {
    this.room?.send(
      Message.WordAndCardSelection,
      'Message.WordAndCardSelection'
    );
  }

  // обработчик события, генерируемого при изменении состояния игры
  onceStateChanged(
    cb: (state: IFantszuimState & Schema) => void,
    context?: any
  ) {
    this.events.on('on-state-changed', cb, context);
  }

  onGameStatusChanged(
    cb: (state: IFantszuimState & Schema) => void,
    context?: any
  ) {
    this.events.on('game-status-changed', cb, context);
  }

  onMyFieldCard(cb: (card: number) => void, context?: any) {
    this.events.on('on-field-card', cb, context);
  }

  onMyVoteCard(cb: (card: number) => void, context?: any) {
    this.events.on('on-vote-card', cb, context);
  }

  onMyHandChanged(cb: (hand: number[]) => void, context?: any) {
    this.events.on('my-hand-changed', cb, context);
  }

  // onCardCelection(cb: (word: string) => void, context?: any) {
  //   this.events.on('card-selection', cb, context);
  // }

  onSeatsChanged(cb: (players: Player[]) => void, context?: any) {
    this.events.on('seats-changed', cb, context);
  }
}

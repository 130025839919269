import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  NgZone,
  Optional,
  SkipSelf,
} from '@angular/core';
import * as Phaser from 'phaser';

import NinePatchPlugin from 'phaser3-rex-plugins/plugins/ninepatch-plugin.js';
import GameManager from './scenes/GameManager';
import Game from './scenes/Game';
import HUD from './scenes/HUD';
import Login from './scenes/Login';
import Options from './scenes/Options';
import GoogleSignUp from './scenes/GoogleSignUp';
import Language from './scenes/Language';
import Lobby from './scenes/Lobby';
import Server from './services/Server';

/**
 * * The PhaserInstance is a singleton that controls the Game Scene, which is the UI portion of the Game Engine
 */

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
})
export class PhaserSingletonService {
  // * We need the Phaser.Game to live inside our own class because extending Phaser.Game would require a super call
  public static activeGame: Phaser.Game;
  private static ngZone: NgZone;
  public static language: String = undefined;
  public static languageConstants: any = undefined;
  public static user: any = undefined;
  public static actionsHistory: string[] = []; // * Since phaser is a singleton, let's store the history of actions here for all components.
  public static server: Server = undefined;

  constructor(
    private _ngZone: NgZone,
    @Optional() @SkipSelf() parentModule?: PhaserSingletonService
  ) {
    if (parentModule) {
      console.error(
        'Phaser Singleton is already loaded. Import it in the AppModule only'
      );
    } else {
      PhaserSingletonService.ngZone = this._ngZone;
      PhaserSingletonService.actionsHistory.push('Initializing Phaser...');
    }
  }

  /**
   * * This function is required for singleton instance
   *
   * @returns PhaserSingletonService & List of Providers
   */
  public static forRoot(): ModuleWithProviders<PhaserSingletonService> {
    return {
      ngModule: PhaserSingletonService,
      providers: [],
    };
  }

  /**
   * * When A user Logs out, destroy the active game.
   */
  public static destroyActiveGame() {
    //* Param 1: Set to true if you would like the parent canvas element removed from the DOM.
    //* Param 2: Set to false  If you do need to create another game instance on the same page
    if (PhaserSingletonService.activeGame) {
      PhaserSingletonService.activeGame.destroy(true, false);
    }
  }

  /**
   * * Initializes the active Phaser.Game
   * * The Phaser.Game instance owns Scene Manager, Texture Manager, Animations FrameHandler, and Device Class as GLOBALS
   * * The Scene Manager owns the individual Scenes and is accessed by activeGame.scene
   * * Each Scene owns it's own "world", which includes all game objects.
   * ! GameInstance must be the parent class to scenes.
   * ! Should only be called *when* we want it to load in memory.  I.e. during simulation.
   */
  public static async init() {
    console.warn('phaser-singleton init');
    /**
     * * Phaser by default runs at 60 FPS, and each frame that triggers change detection in Angular which causes
     * * Performance to go out the door.  NgZone's runOutsideAngular will prevent Phaser from automatically hitting change detection
     * * https://angular.io/guide/zone
     */
    PhaserSingletonService.ngZone.runOutsideAngular(() => {
      if (!PhaserSingletonService.activeGame) {
        // To scale game to always fit in parent container
        // https://photonstorm.github.io/phaser3-docs/Phaser.Scale.ScaleManager.html
        PhaserSingletonService.activeGame = new Phaser.Game({
          type: Phaser.AUTO,
          width: 1920,
          height: 1080,
          parent: 'fantazium',
          physics: {
            default: 'arcade',
            arcade: {
              //debug: true,
              gravity: { y: 200 },
            },
          },
          scale: {
            mode: Phaser.Scale.FIT,
            autoCenter: Phaser.Scale.CENTER_BOTH,
            width: 1920,
            height: 1080,
            min: {
              width: window.innerWidth,
              height: (1080 * window.innerWidth) / 1920,
            },
          },
          plugins: {
            global: [
              {
                key: 'rexNinePatchPlugin',
                plugin: NinePatchPlugin,
                start: true,
              },
            ],
          },
          backgroundColor: '#023875',
          dom: {
            createContainer: true,
          },
          scene: [
            Language,
            GameManager,
            Login,
            Game,
            HUD,
            Options,
            GoogleSignUp,
            Lobby,
          ], // первая сцена массива управляет остальными
        });
      }
    });
  }

  /**
   * * gets the actionsHistory
   *
   * @returns string[]
   */
  public static getActionsHistory() {
    return PhaserSingletonService.actionsHistory;
  }
}

import * as Phaser from 'phaser';
import Popup from '../helpers/Popup';
import { Storage } from '@capacitor/storage';
import { PhaserSingletonService } from '../phaser-singleton.module';

export default class Language extends Phaser.Scene {
  popup: Popup;
  constructor(public phaserInstance: PhaserSingletonService) {
    super('language');
  }
  languages = ['eng', 'deu', 'fra', 'esp', 'ita', 'rus'];

  preload() {
    this.load.image('bg', 'assets/UI/popup_bg.png');

    this.languages.forEach((lang) => {
      this.load.image(
        `language_small_${lang}`,
        `assets/UI/language_small_${lang}.png`
      );
    });
  }

  async create() {
    const language = await Storage.get({ key: 'language' });
    if (language.value !== null) {
      PhaserSingletonService.language = language.value;
      this.scene.start('gamemanager');
    }

    this.popup = new Popup(this, 600, 400, '', false);
    let f = 0;
    let r = 0;
    this.languages.forEach((lang) => {
      const flag = this.add
        .image(-155 + f * 160, -90 + r * 160, `language_small_${lang}`)
        .setInteractive();
      flag.on('pointerup', async () => {
        Storage.set({ key: 'language', value: lang });
        PhaserSingletonService.language = lang;
        this.scene.start('gamemanager');
      });
      this.popup.add(flag);
      f++;
      if (f == 3) {
        f = 0;
        r = 1;
      }
    });
  }
}

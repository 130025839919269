import { GameObjects } from 'phaser';
import * as Phaser from 'phaser';

// 9-slice plugin https://rexrainbow.github.io/phaser3-rex-notes/docs/site/ninepatch/
import NinePatch from 'phaser3-rex-plugins/plugins/ninepatch.js';

export default class Options extends Phaser.Scene {
  constructor() {
    super('options');
  }

  preload() {
    this.load.image('bg', 'assets/UI/popup_bg.png');
    this.load.image('bg_login', 'assets/UI/popup_bg_login.png');
    this.load.image('close', 'assets/UI/popup_btn_close.png');
    this.load.image('button_blue', 'assets/UI/button_blue.png');
    this.load.image('popup_title_line', 'assets/UI/popup_title_line.png');
  }

  popup(_width: number, _height: number) {
    // определяем координаты центра окна
    const { width, height } = this.sys.game.canvas;
    const centerX = width / 2;
    const centerY = height / 2;

    this.input.setTopOnly(true);

    // фон модального окна
    var windowNinePatch = new NinePatch(this, {
      x: 0,
      y: 0,
      width: _width,
      height: _height,
      key: 'bg',
      columns: [25, 1, 25],
      rows: [30, 2, 30],
      preserveRatio: false,
    });
    const window = this.add.existing(windowNinePatch);

    const glow = this.add
      .image(0, 0, 'glow')
      .setScale((_height - 80) / 970)
      .setAlpha(0.08);

    const title = this.add
      .text(0, -_height / 2 + 15, 'авторизация', {
        fontFamily: 'Oswald',
        color: 'white',
        fontSize: '40px',
      })
      .setShadow(3, 3, '#000000', 1, true, true);
    title.setX(-title.width / 2);

    const popup_title_line = this.add
      .image(0, -_height / 2 + 80, 'popup_title_line')
      .setScale((_height - 100) / 1332);

    var buttonNinePatch = new NinePatch(this, {
      x: 0,
      y: 90,
      width: _width - 200,
      height: 70,
      key: 'button_blue',
      columns: [25, undefined, 25],
      rows: [65, undefined, 79],
      preserveRatio: false,
    });

    const button = this.add.existing(buttonNinePatch).setInteractive();
    const button_text = this.add
      .text(-50, 60, 'ВОЙТИ', {
        fontFamily: 'Oswald',
        color: 'white',
        fontSize: '40px',
      })
      .setShadow(2, 2, '#000000', 1, true, true);

    const close = this.add
      .image(_width / 2 - 15, -_height / 2 + 15, 'close')
      .setScale(0.5)
      .setInteractive();

    // за заголовок можно тянуть
    // const geom = new Phaser.Geom.Rectangle(
    //   -_width / 2,
    //   -_height / 2,
    //   _width,
    //   _height
    // );
    var container = this.add
      .container(centerX, centerY, [
        window,
        glow,
        title,
        popup_title_line,
        button,
        button_text,
        close,
      ])
      //.setInteractive(geom, Phaser.Geom.Rectangle.Contains)
      .setDepth(20);
    // this.input.setDraggable(container);
    // this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
    //   gameObject.x = dragX;
    //   gameObject.y = dragY;
    // });

    button.on('pointerover', () => {
      button.setScale(1.1);
      button_text.setColor('#ffc833');
    });
    button.on('pointerout', () => {
      button.setScale(1);
      button_text.setColor('white');
    });
    button.on('pointerup', () => {
      closePopUp();
    });

    close.on('pointerover', () => {
      close.setRotation(0.75);
    });
    close.on('pointerout', () => {
      close.setRotation(0);
    });
    close.on('pointerup', () => {
      closePopUp();
    });

    const closePopUp = () => {
      this.input.setTopOnly(false);
      this.scene.stop();
    };
  }

  create() {
    this.popup(480, 350);
  }
}

import * as Phaser from 'phaser';
//import 'dotenv/config';
//import * as Colyseus from 'colyseus.js';
//import { InputType } from 'zlib';

export default class HelloWorldScene extends Phaser.Scene {
  //  private client?: Colyseus.Client;

  constructor() {
    super('login');
  }

  // init() {
  //   console.log(process.env.COLYSEUS_SERVER);
  //   this.client = new Colyseus.Client(process.env.COLYSEUS_SERVER);
  // }

  preload() {
    //    this.load.html('nameform', 'assets/loginform.html');
    this.load.html('signupform', 'assets/signup.html');
  }

  async create() {
    //var text = this.add.text(10, 10, '', { color: 'white', fontFamily: 'Arial', fontSize: '32px ' });
    var element = this.add.dom(300, 800).createFromCache('signupform');
    console.log(element);

    // element.setPerspective(1500);
    // element.addListener('click');
    // element.addListener('keyup');

    // element.on('click', function (event) {
    //   if (event.target.name === 'loginButton') {
    //   }
    // });

    // element.on('keyup', function (event) {
    //   if (event.target.name == 'query') {
    //     var query = element.getChildByName('query') as HTMLInputElement;

    //     fetch('https://www.all-soccer.ru/getword.php?q=' + query.value)
    //       .then(function (response) {
    //         return response.json();
    //       })
    //       .then(function (data) {
    //         var selectedWord = element.getChildByID('selectedWord') as HTMLSelectElement;
    //         for (let i = selectedWord.options.length; i >= 0; i--) {
    //           selectedWord.remove(i);
    //         }
    //         data.forEach((e) => {
    //           selectedWord[selectedWord.options.length] = new Option(e, e);
    //         });
    //         selectedWord.selectedIndex = 0;
    //       })
    //       .catch(function () {
    //         console.log('Booo');
    //       });
    //   }
    // });

    // element.on('click', function (event) {
    //   if (event.target.name === 'loginButton') {
    //     var selectedWord = element.getChildByID('selectedWord') as HTMLSelectElement;
    //     room?.send('card_placed', selectedWord.options[selectedWord.selectedIndex].value);
    //   }
    // });

    this.tweens.add({
      targets: element,
      y: 320,
      duration: 500,
      ease: 'Power3',
    });

    //const room = await this.client?.joinOrCreate('my_room');

    //console.log(room);

    // room?.onMessage('card_placed', (message) => {
    //   text.setText(message);
    //   //console.log(message);
    // });

    // room?.onMessage('keydown', (message) => {
    //   console.log(message);
    // });

    // this.input.keyboard.on('keydown', (e) => {
    //   room?.send('keydown', e.key);
    // });
  }
}

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';

import { Component } from '@angular/core';
import { PhaserSingletonService } from '../phaser-singleton.module';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    public phaserInstance: PhaserSingletonService,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.platform
      .ready()
      .then(async () => {
        await PhaserSingletonService.init();
      })
      .then(async () => {
        await SplashScreen.hide();
      });
  }
}

export enum Message {
  WordAndCardSelection,
  CardSelection,
  CardVote,
  cardPlaced,
  notYourTurn,
  myHand,
  myFieldCard,
  myVoteCard,
}

import Seat from './Seat';

export enum GameStatus {
  Pending,
  PlayerSelectWordAndCard,
  OtherPlayersSelectCards,
  Vote,
  CalculateTurnResults,
  Finished,
}

export default interface IFantaziumState {
  activePlayer: number;
  MaxClients: number;
  gameStatus: GameStatus;
  gameStartTime: number;
  CardCelectionTime: number;
  WordAndCardSelectionTime: number;
  currentStatusBeginTime: number;
  seats: Seat[];
  currentWord: string;
  cardsToVote: number[];
  votes: number[];
}

import * as Phaser from 'phaser';
import Image from 'phaser3-rex-plugins/plugins/gameobjects/mesh/perspective/image/Image';
import Text from 'phaser3-rex-plugins/plugins/gameobjects/text/textbase/Text';
import NinePatch from 'phaser3-rex-plugins/plugins/ninepatch.js';

export default class Popup extends Phaser.GameObjects.Container {
  constructor(
    _scene: Phaser.Scene,
    _width: number,
    _height: number,
    _title: string,
    isClose: boolean = true
  ) {
    super(_scene);
    this.scene = _scene;
    this.create(_width, _height, _title, isClose);
  }

  create(_width: number, _height: number, _title: string, isClose: boolean) {
    // определяем координаты центра окна
    const { width, height } = this.scene.sys.game.canvas;
    const centerX = width / 2;
    const centerY = height / 2;

    this.scene.input.setTopOnly(true);

    // фон модального окна
    var windowNinePatch = new NinePatch(this.scene, {
      x: 0,
      y: 0,
      width: _width,
      height: _height,
      key: 'bg',
      columns: [25, 1, 25],
      rows: [30, 2, 30],
      preserveRatio: false,
    });
    const window = this.scene.add.existing(windowNinePatch);
    this.add(window);

    if (_title.length > 0) {
      const title = this.scene.add
        .text(0, -_height / 2 + 15, _title, {
          fontFamily: 'Oswald',
          color: 'white',
          fontSize: '40px',
        })
        .setShadow(3, 3, '#000000', 1, true, true);
      title.setX(-title.width / 2);
      title.setName('title');
      this.add(title);

      const popup_title_line = this.scene.add
        .image(0, -_height / 2 + 85, 'popup_title_line')
        .setScale((_width - 40) / 2338);

      popup_title_line.setName('title_line');
      this.add(popup_title_line);
    }

    if (isClose) {
      const close = this.scene.add
        .image(_width / 2 - 15, -_height / 2 + 15, 'close')
        .setScale(0.5)
        .setInteractive();
      this.add(close);

      close.on('pointerover', () => {
        close.setRotation(0.75);
      });
      close.on('pointerout', () => {
        close.setRotation(0);
      });
      close.on('pointerup', () => {
        closePopUp();
      });
    }

    this.scene.add.existing(this).setX(centerX).setY(centerY);

    const closePopUp = () => {
      console.log('closePopUp', this);
      this.setVisible(false);
    };
  }

  public resizePopup(height: number): void {
    const np = this.list[0] as NinePatch;
    np.resize(np.width, height);

    if (this.list[1].name === 'title') {
      const title = this.list[1] as Text;
      title.setY(-height / 2 + 15);
      const popup_title_line = this.list[2] as Image;
      popup_title_line.setY(-height / 2 + 85);
    }
  }
}
